<template>
	<div>
		<!-- <BaseEmpty width="97"> -->
		<BaseEmpty>
			<template>
				<div class="filter-wrap mb-1">
					<div
						v-if="possuiPermissao('GER_I_ORCAMENTO') || possuiPermissao('GER_U_ORCAMENTO')"
						class="filter mr-2 icon-add-transaction add-btn"
						@click="showBudget()"
						title="Novo Orçamento"
					>
						<FilePlusIcon/>
					</div>
					<div
						v-if="possuiPermissao('GER_D_ORCAMENTO1')"
						class="filter mr-2 icon-delete"
						v-bind:class="!itemSelected ? 'filter-disable' : ''"
						@click="confirmaApagar('All')"
						title="Apagar Selecionados"
					>
						<Trash2Icon/>
					</div>
					<div
						class="filter"
						v-bind:class="[showFiltersForm ? 'filter-active' : '']"
						@click="filterDropDownActive"
						title="Alterar Filtros"
					>
						<FilterIcon/>
					</div>
				</div>

				<b-modal
					ref="modalBudget"
					hide-footer
					:title="titleModalBudget"
					size="xl"
					no-overflow
					hide-no-focus="true"
				>
					<div class="modal-edicao mb-3 modal-edicao-label">
						<b-tabs ref="tabs" class="text-parcela h-modal">
							<b-tab title="Cliente" class="p-2 border border-top-none">
								<b-row class="mt-2 mb-0">
									<b-col class="text-parcela pr-0">
										<span class="label-conta">Data</span>
										<div class="d-flex">
											<datepicker
												class="w-100"
												placeholder=""
												id="data-lancamento"
												input-class="bg-white"
												v-model="budgetUpsert_date"
												:format="formatter"
												:language="ptBR"
												:bootstrap-styling="true"
												data-cy="Data"
												:disabled="editing"
											/>
										</div>
									</b-col>
									<b-col class="text-parcela pr-0 pl-1">
										<span class="label-conta">
											Cliente
										</span>
										<b-form-group>
											<b-form-input
												v-model="budgetUpsert_customer"
												list="options"
												placeholder="Escreve e seleciona uma opção"
												@input="findCustomers"
												v-validate="{ required: true }"
												:state="validateState('budgetUpsert_customer')"
												autocomplete="off"

											/>
											<section v-if="visibleSuggestion.customer" id="options" class="detalist">
												<option
													class="detalist-option"
													v-for="option in optionsCustomer" :key="option.idCustomer"
													@click="changeSelect(option, 'customer')"
												>
													{{ option.description }}
												</option>
											</section>
										</b-form-group>
									</b-col>
									<b-col  class="d-block text-parcela pl-1">
										<span class="label-conta">CPF/CNPJ</span>
										<b-form-input
											name="identity"
											class="modal-edicao-input"
											v-model="budgetUpsert_identity"
											autocomplete="off"
											placeholder=""
											v-validate="{ required: false }"
											:state="validateState('identity')"
											:disabled="dataDisable.customer"
										/>
									</b-col>
								</b-row>
								<b-row class="mb-3 mt-0">
									<b-col class="d-block text-parcela pr-0">
										<span class="label-conta">Endereço</span>
										<b-form-input
											name="address"
											class="modal-edicao-input"
											v-model="budgetUpsert_address"
											autocomplete="off"
											placeholder=""
											v-validate="{ required: false }"
											:state="validateState('address')"
											:disabled="dataDisable.customer"
										/>
									</b-col>
									<b-col class="d-block text-parcela pl-1">
										<span class="label-conta">Telefone</span>
										<b-form-input
											name="phone"
											class="modal-edicao-input"
											v-model="budgetUpsert_phone"
											autocomplete="off"
											placeholder=""
											v-validate="{ required: false }"
											:state="validateState('phone')"
											:disabled="dataDisable.customer"
										/>
									</b-col>
								</b-row>
								<b-row class="mt-2">
									<b-col class="d-block text-parcela">
										<span class="label-conta">Observações</span>
										<b-form-input
											name="comments"
											class="modal-edicao-input"
											v-model="budgetUpsert_comments"
											autocomplete="off"
											placeholder=""
											v-validate="{ required: false }"
											:state="validateState('comments')"
										/>
									</b-col>
								</b-row>
							</b-tab>
							<b-tab title="Produtos/Serviços" class="p-2 border border-top-none">
								<b-row class="mt-2">
									<b-col :cols="colsBudget[0]"
										class="text-parcela"
										v-bind:class="!isMobile ? 'pr-0' : ''"
									>
										<span class="label-conta">
											Produto/Serviço
										</span>
										<b-form-group>
											<b-form-input
												name="budgetUpsert_product"
												v-model="budgetUpsert_product"
												list="options"
												placeholder="Escreve e seleciona uma opção"
												@input="findProducts"
												autocomplete="off"

											/>
											<section v-if="visibleSuggestion.product" id="options" class="detalist" name="list-product">
												<option
													class="detalist-option"
													v-for="option in optionsProducts" :key="option.idProduct"
													@click="changeSelect(option, 'product')"
												>
													{{ option.description }}
												</option>
											</section>
										</b-form-group>
									</b-col>
									<b-col :cols="colsBudget[1]"
										class="d-block text-parcela"
										v-bind:class="!isMobile ? 'pl-1 pr-0' : 'pr-0'"
										>
										<span class="label-conta">Quantidade</span>
										<b-form-input
											name="budgetUpsert_quantity"
											class="modal-edicao-input number-class"
											v-model="budgetUpsert_quantity"
											autocomplete="off"
											placeholder=""
											:disabled="!budgetUpsert_idProduct"
											@input="updateValue('budgetUpsert_quantity')"
											inputmode="numeric"
										/>
									</b-col>
									<b-col :cols="colsBudget[2]"
										class="d-block text-parcela pl-1 pr-0"
									>
										<span class="label-conta">Preço</span>
										<b-form-input
											name="budgetUpsert_amount"
											class="modal-edicao-input number-class"
											v-model="budgetUpsert_amount"
											autocomplete="off"
											placeholder=""
											:disabled="!budgetUpsert_idProduct"
											@input="updateValue('budgetUpsert_amount')"
											inputmode="numeric"
										/>
									</b-col>
									<b-col :cols="colsBudget[3]"
										class="d-block text-parcela pl-1 pr-0 mr-0"
									>
										<span class="label-conta">Total</span>
										<section class="d-flex input-value">
											<b-form-input
												name="total"
												class="modal-edicao-input number-class"
												v-model="budgetUpsert_total"
												autocomplete="off"
												placeholder=""
												:disabled="true"
												@input="updateValue('budgetUpsert_total')"
												inputmode="numeric"
											/>
											<b-button
												class="btn-pay btn-pay-add"
												@click="addProduct(budgetUpsert_idProduct)"
												:disabled="invalidProduct"
											>
												+
											</b-button>
										</section>
									</b-col>
								</b-row>
								<section
									v-if="selectedProducts.length"
									class="table-parcelas mt-2"
								>
									<b-row
										v-for="i in selectedProducts.length" :key="i"
										class="mt-1 mb-1 mr-0 justify-items"
									>
										<b-col :cols="colsBudget[0]"
											class="d-flex label-parcela-table pl-1 pr-0"
											v-bind:class="!isMobile ? '' : 'mb-2'"
										>
											<span style="width: 2rem; text-align: left;" class="mr-1">{{ i }}</span>
											<b-form-input
												class="modal-edicao-input"
												:disabled="!selectedProducts[i - 1].eventual"
												v-model="selectedProducts[i - 1].description"
												autocomplete="off"
												placeholder=""
											/>
										</b-col>
										<b-col :cols="colsBudget[1]"
											class="d-flex label-parcela-table pr-0"
											v-bind:class="!isMobile ? 'pl-1' : 'pl-0'"
										>
											<b-form-input
												:name="`selectedProducts-${i}-quantity`"
												class="modal-edicao-input number-class"
												v-model="selectedProducts[i - 1].quantity"
												autocomplete="off"
												placeholder=""
												inputmode="numeric"
												:disabled="true"
											/>
										</b-col>
										<b-col :cols="colsBudget[2]"
											class="d-flex label-parcela-table pl-1 pr-0"
										>
											<b-form-input
												:name="`selectedProducts-${i}-amount`"
												class="modal-edicao-input number-class"
												v-model="selectedProducts[i - 1].amount"
												autocomplete="off"
												placeholder=""
												inputmode="numeric"
												:disabled="true"
											/>
										</b-col>
										<b-col :cols="colsBudget[3]"
											class="d-flex label-parcela-table pl-1"
											v-bind:class="!isMobile ? '' : 'pr-0'"
										>
											<b-form-input
												:name="`selectedProducts-${i}-total`"
												class="modal-edicao-input number-class"
												v-model="selectedProducts[i - 1].total"
												autocomplete="off"
												placeholder=""
												inputmode="numeric"
												:disabled="true"
											/>
											<div
												class="btn-pay btn-pay-del"
												title="Apagar"
												@click="delProduct(i - 1)"
											>
												<Trash2Icon size="24" alt="Apagar"/>
											</div>
										</b-col>
									</b-row>
								</section>
							</b-tab>
						</b-tabs>
					</div>
					<b-button class="ml-1 mt-3 float-left btn-modal btn-cancel" @click="fecharModal('modalBudget')">Cancelar</b-button>
					<b-button class="mt-3 float-left btn-modal btn-limpar" @click="limparModal()">Limpar</b-button>
					<b-button
						class="mt-3 ml-1 float-right btn-modal btn-salvar"
						@click="save"
						:disabled="invalidForm || !idCustumerValid || notProductsAdded"
					>
						Salvar
					</b-button>
				</b-modal>
				<vue-html2pdf
					:show-layout="false"
					:enable-download="true"
					:preview-modal="false"
					:paginate-elements-by-height="1400"
					:pdf-quality="2"
					pdf-format="A4"
					pdf-orientation="portrait"
					pdf-content-width="1200px"
					:html-to-pdf-options="htmlToPdfOptions"
					ref="html2Pdf"
					:float-layout="floatLayout"
					:manual-pagination="false"
					@hasDownloaded="finishGeneratePdf"
				>
					<section slot="pdf-content" class="pdf-content">
						<ReportPDF
							:title="title"
							:rows="rowsReport"
							:headerData="headerData"
							:fields="fields"
							:config="{
								type: 'document',
								showLogo: false,
								showTitle: true,
								titleDocument: 'Orçamento'
							}"
						/>
					</section>
				</vue-html2pdf>
				<b-modal
					ref="modalDetailsBudget"
					hide-footer
					:hide-header="hideHeaderModal"
					title="Detalhes do orçamento"
					size="lg"
					no-overflow
					hide-no-focus
				>
					<div>
						<div v-if="hideHeaderModal" class="title-modal" style="top: 1Rem">
							<div class="title-text-modal">Detalhes do orçamento</div>
							<div
								class="float-right btn-hover-modal"
								@click="fecharModal('modalDetailsBudget')"
							>
								<xIcon/>
							</div>
						</div>
						<div class="mt-5 mb-1" style="margin-left: -20px;">
							<div class="container-head">
								<div
									v-if="possuiPermissao('GER_I_ORCAMENTO') || possuiPermissao('GER_U_ORCAMENTO')"
								>
									<b-col class="container-btn-print" cols="1">
										<b-dropdown no-caret left variant="link" title="Imprimir">
											<template #button-content>
												<printer-icon class="icon-print filter"/>
											</template>
											<b-dropdown-item @click="exportDataBudget('csv')">CSV</b-dropdown-item>
											<b-dropdown-item @click="exportDataBudget('xlsx')">EXCEL</b-dropdown-item>
											<b-dropdown-item @click="exportDataBudget('pdf')">PDF</b-dropdown-item>
										</b-dropdown>
									</b-col>
								</div>
							</div>
						</div>
						<div v-if="linhas.length" class="table-responsive">
							<div class="d-flex float-right">
								<span class="mr-2">Data:</span>
								<span>{{ dataBudgetSelected.dateFormated  || '-' }}</span>
							</div>
							<br>
							<div class="d-flex float-right">
								<span class="mr-2">Orçamento:</span>
								<span >{{ dataBudgetSelected.numDoc || '-' }}</span>
							</div>
							<div class="d-flex">
								<span class="ml-2 mr-2"><b>Cliente:</b></span>
								<span class="mr-3">{{ dataBudgetSelected.nameCustomer || '-' }}</span>
							</div>
							<DataTable
								class="data-table"
								:loading="loading"
								:colunas="detailsBudgetCols"
								:linhas="detailsBudgetLines"
								errMsg="Erro ao obter informações"
								:nosearch="true"
								:nofilters="true"
								name="detailsAccount"
								:hasPagination="true"
								:noedit="true"
								:acoes="false"
								:selectItem="false"
								:alignColumns="detailsAlignCols"
								scroll
								:sortable="false"
								:state="{
									sortBy: sortTable.sortBy,
									sortAsc: 0,
									query: ''
								}"
								:async="true"
							/>
							<div class="d-flex float-right">
								<h4 class="mr-5">Total:</h4>
								<h4 class="mr-3">{{ dataBudgetSelected.grandTotal || '0,00' }}</h4>
							</div>
							<br>
							<br>
							<div class="d-flex">
								<span class="ml-2 mr-2"><b>Observações:</b></span>
								<span class="mr-3">{{ dataBudgetSelected.comments || "" }}</span>
							</div>
						</div>
						<div v-else class="alert alert-warning" role="alert">
							Erro ao obter informações
						</div>
					</div>
				</b-modal>

				<div v-if="showFiltersForm" class="filters-form content-card">
					<div>
						<b-row
							class="filters-orientation filters-section mb-2"
						>
							<b-col class="filter-item">
								<label class="mb-0">Data inicial</label>
								<div class="d-flex">
									<datepicker
										class="w-100"
										placeholder="Início"
										id="data-inicial-historica"
										input-class="bg-white"
										v-model="inicio"
										:format="formatter"
										:language="ptBR"
										:bootstrap-styling="true"
										data-cy="Data Inicial"
										@input="setFilterDateEnd(inicio)"
									/>
								</div>
							</b-col>
							<b-col class="filter-item">
								<label class="mb-0">Data final</label>
								<div class="d-flex">
									<datepicker
										class="w-100"
										placeholder="Fim"
										id="data-final-historica"
										input-class="bg-white"
										v-model="fim"
										:format="formatter"
										:language="ptBR"
										:bootstrap-styling="true"
										data-cy="Data Final"
									/>
								</div>
							</b-col>
						</b-row>
						<b-row
							class="filters-orientation filters-section-seconds"
						>
							<b-col class="filter-item">
								<label class="mb-0">
									Cliente
								</label>
								<input
									id="comments"
									class="form-control time-pick"
									v-model="customer"
								/>
							</b-col>
							<b-col class="filter-item">
								<label class="mb-0">
									Observações
								</label>
								<input
									id="comments"
									class="form-control time-pick"
									v-model="comments"
								/>
							</b-col>
						</b-row>
						<div class="mt-4 search-button" @click="findBudgets(true)">
							<SearchIcon/>
						</div>
					</div>
				</div>
				<b-row class="row-1">
				</b-row>
			</template>
		</BaseEmpty>
		<Base style="margin-top: -10px">
			<template>
				<div v-if="linhas.length" class="head-row mb-2 width-pagination-items mt-1">
					<div class="text-head-row width-pagination">
						<label class="text-head-row ml-0">
							Resultado {{ pagination.offset + 1 }}-{{ pagination.offset + linhas.length }} de {{ pagination.count }}
						</label>
						<b-pagination
							class="mr-0 mb-2 ml-3"
							id="page"
							v-model="currentPage"
							:total-rows="pagination.count"
							:per-page="pagination.limit.value"
						></b-pagination>
					</div>
					<b-form
						class="text-head-row width-pagination">
						<label class="text-head-row ml-0 w-60">
							Itens por página
						</label>
						<b-form-select
							class="mr-0 mb-2"
							v-model="pagination.limit.value"
							name="itens-por-pagina"
							:options="optionsItensPorPag"
							@input="changeItensByPage()"
							:disabled="loading"
						/>
					</b-form>
				</div>
				<div v-if="linhas.length" class="table-responsive">
					<DataTable v-if="!isMobile"
						class="data-table"
						:loading="loading"
						:colunas="colunas"
						:linhas="linhas"
						:errMsg="errMsg"
						:nosearch="true"
						:nofilters="true"
						name="transacoes"
						:hasPagination="true"
						:noedit="true"
						:acoes="false"
						:permissaoEdit="possuiPermissao('GER_U_ORCAMENTO')"
						:permissaoDelete="possuiPermissao('GER_D_ORCAMENTO')"
						@clickDelete="confirmaApagar"
						@clickEdit="showBudget"
						:selectItem="false"
						:alignColumns="alignColumnsTable"
						@clickSortTableBy="(v) => sortTableBy(v)"
						:state="{
							sortBy: sortTable.sortBy,
							sortAsc: sortTable.order === 1,
							query: ''
						}"
						:async="true"
						@clickCheck="showButonsTop"
						@clickDetails="showDetailsBudget"
						:details="true"
					/>
					<CardTransaction v-else
						:loading="loading"
						:colunas="colunas"
						:linhas="linhas"
						:errMsg="errMsg"
						:acoes="false"
						:permissaoEdit="possuiPermissao('GER_U_ORCAMENTO')"
						:permissaoDelete="possuiPermissao('GER_D_ORCAMENTO')"
						@clickDelete="confirmaApagar"
						@clickEdit="showBudget"
						:selectItem="false"
						:globalAcoes="false"
						@clickCheck="showButonsTop"
						:colsDataCard="colsDataCard"
						:details="true"
						@clickDetails="showDetailsBudget"
						:showSelectItem="false"
					/>
				</div>
				<div v-else class="alert alert-warning" role="alert">
					{{ errMsg }}
				</div>
			</template>
		</Base>
	</div>
</template>

<script>
	import dayjs from "dayjs";
	import "dayjs/locale/pt-br";
	import Datepicker from "vuejs-datepicker";
	import { ptBR } from "vuejs-datepicker/dist/locale";

	import BaseEmpty from "@/templates/BaseEmpty";
	import Base from "@/templates/Base.vue";
	import DataTable from "@/components/DataTable";
	import { getDateMonthEnd, numberFormatted, stringToNumber, stringToDateUTC, validateNumber, formatterSimple, exportCSVFile, stringToNumberToFixed } from "@/helpers/common";
	import { CustomerService } from "../../services/customer";
	import { possuiPermissao } from "../../helpers/permissions";
	import { hasFieldsWithErrors } from "@/helpers/validators";
	import { ProductService } from "../../services/product";

	import { BudgetService } from "../../services/budget";
	import CardTransaction from "@/components/CardTransaction.vue";

	import * as XLSX from "xlsx";
	import VueHtml2pdf from "vue-html2pdf";
	import ReportPDF from "../Reports/ReportPDF.vue";
	import "./styles.css";

	export default {
		components: {
			BaseEmpty,
			Base,
			DataTable,
			Datepicker,
			CardTransaction,
			VueHtml2pdf,
			ReportPDF
		},

		inject: ["parentValidator"],

		data () {
			return {
				customerService: new CustomerService(),
				budgetService: new BudgetService(),
				productService: new ProductService(),
				possuiPermissao,

				ready: true,
				loading: false,
				updating: false,
				ptBR,
				selectCustomer: [],
				sortTable: {
					sortBy: 2,
					order: -1
				},
				itemSelected: false,
				isMobile: false,

				// filters
				customer: "",
				inicio: null,
				fim: null,
				comments: "",
				selectProduct: [],
				selectedProduct: [],
				titleModalBudget: "",

				// dados model
				budgetUpsert_comments: "",
				budgetUpsert_date: new Date(),
				budgetUpsert_idCustomer: null,
				budgetUpsert_customer: "",
				budgetUpsert_identity: "",
				budgetUpsert_address: "",
				budgetUpsert_phone: "",
				budgetUpsert_customer_evetual: false,

				budgetUpsert_idProduct: null,
				budgetUpsert_product: "",
				budgetUpsert_quantity: "1,00",
				budgetUpsert_amount: "0,00",
				budgetUpsert_total: "0,00",
				budgetUpsert_product_evetual: false,
				optionsProducts: [],
				selectedProducts: [],

				budgets: [],
				optionsCustomer: [],
				visibleSuggestion: {
					customer: false,
					product: false
				},
				idCustumerValid: false,
				dataDisable: {
					customer: true,
					product: true
				},
				notProductsAdded: true,
				invalidProduct: true,
				grandTotal: 0,
				showFiltersForm: false,
				errMsg: "Nenhuma transação cadastrada!",
				colunas: [
					"Orçamento",
					"Cliente",
					"Data",
					"Valor Total",
					"Observações"
				],
				alignColumnsTable: [
					"left",
					"left",
					"left",
					"right",
					"left"
				],
				colsDataCard: {
					0: {
						idx: 0,
						label: "Orçamento",
						textAlign: "center",
						cols: 2,
						visible: true
					},
					1: {
						idx: 1,
						label: "Cliente",
						textAlign: "left",
						cols: 10,
						visible: true
					},
					2: {
						idx: 2,
						label: "Data",
						textAlign: "center",
						cols: 3,
						visible: true
					},
					3: {
						idx: 3,
						label: "Valor Total",
						textAlign: "right",
						cols: 3,
						visible: true
					},
					4: {
						idx: 4,
						label: "Observações",
						textAlign: "left",
						cols: 6,
						visible: true
					},
					5: {
						idx: null,
						label: "",
						textAlign: "",
						cols: 0,
						visible: false
					},
					6: {
						idx: null,
						label: "",
						textAlign: "left",
						cols: 12,
						visible: false
					},
					7: {
						idx: null,
						label: "",
						textAlign: "",
						cols: 0,
						visible: false
					}
				},

				detailsBudgetLines: [],
				detailsAlignCols: [
					"left",
					"right",
					"right",
					"right"
				],
				detailsBudgetCols:  [
					"Produto",
					"Qtde.",
					"Preço",
					"Total"
				],

				linhas: [],
				showAddModal: false,
				titleConta: "",
				invalidForm: true,
				editing: false,
				permitePagamento: null,
				selectItem: false,

				pagination: {
					count: 0,
					page: 1,
					offset: 0,
					limit: {
						value: 25
					},
					pages: 1
				},
				currentPage: 1,
				optionsItensPorPag: [
					{
						value: 5,
						text: "5 Items"
					},
					{
						value: 25,
						text: "25 Items"
					},
					{
						value: 50,
						text: "50 Items"
					},
					{
						value: 75,
						text: "75 Items"
					},
					{
						value: 100,
						text: "100 Items"
					}
				],
				hideHeaderModal: true,
				floatLayout: true,
				fields : [],
				headerData: {},
				rowsReport: [],
				dataBudgetSelected: {},
				title: "",
				filename: "",
				colsBudget: [
					4,
					2,
					3,
					3
				]
			};
		},

		computed: {
			htmlToPdfOptions () {
				return {
					margin: [10, 0, 10, 0],
					image: {
						type: "jpeg",
						quality: 0.98
					},
					enableLinks: false,
					filename: `${this.filename}.pdf`,
					html2canvas: {
						scale: 1,
						useCORS: true
					},

					jsPDF: {
						format: "a4",
						orientation: "portrait",
						floatPrecision: 16
					}
				};
			}

		},

		watch: {
			currentPage () {
				this.changePage();
			},

			filters () {
				this.linhas = [];
			},

			budgetUpsert_idCustomer: "validateCustomer"
		},

		async mounted () {
			this.checkIfMobile();
			window.addEventListener("resize", this.checkIfMobile);

			await Promise.all([
				this.setFilter()
			]);
			await this.findBudgets();
		},

		beforeDestroy () {
			window.removeEventListener("resize", this.checkIfMobile);
		},

		methods: {
			finishGeneratePdf () {
				this.loading = false;
			},

			validateCustomer () {
				this.idCustumerValid = Boolean(this.budgetUpsert_idCustomer);
			},

			checkIfMobile	() {
				this.isMobile = window.innerWidth <= 768;
				if (this.isMobile) {
					this.colsBudget = [
						12,
						3,
						4,
						5
					];
				} else {
					this.colsBudget = [
						4,
						2,
						3,
						3
					];
				}
			},

			async findCustomers () {
				this.budgetUpsert_idCustomer = null;
				this.budgetUpsert_phone = "";
				this.budgetUpsert_address = "";
				this.budgetUpsert_identity = "";

				this.visibleSuggestion.customer = true;
				const result = await this.customerService.listCustomer({offset: 0, limit: null, customer: this.budgetUpsert_customer, findExact: true }) || {};
				this.selectCustomer = result.rows?.reduce((acc, item) => [ ...acc, { nome: item.description, value: item.idCustomer }], []);
				this.optionsCustomer = result.rows.map(ele => ele);
			},

			async findProducts () {
				this.budgetUpsert_idProduct = null;
				this.invalidProduct = true;
				this.budgetUpsert_quantity = numberFormatted(1);
				this.budgetUpsert_amount = numberFormatted(0);
				this.budgetUpsert_total = numberFormatted(0);

				this.visibleSuggestion.product = true;
				const result = await this.productService.listProduct({offset: 0, limit: null, findDescription: this.budgetUpsert_product, findExact: true }) || {};
				this.selectProduct = result.rows?.reduce((acc, item) => [ ...acc, { nome: item.description, value: item.idProduct }], []);
				this.optionsProducts = result.rows.map(ele => ele);
			},

			setFilter () {
				const fim = new Date();
				const month = fim.getMonth();
				const year = fim.getFullYear();
				const ultimoDiaMes = new Date(year, month + 1, 0).getDate();
				fim.setDate(ultimoDiaMes);
				const inicio = new Date();
				inicio.setDate("1");
				this.inicio = inicio;
				this.fim = fim;
			},

			setFilterDateEnd (date) {
				this.fim = getDateMonthEnd(date);
			},

			changeSelect (option, key) {
				if (key === "customer") {
					this.budgetUpsert_customer = option.description;
					this.budgetUpsert_idCustomer = option.idCustomer;
					this.budgetUpsert_phone = option.cellPhone || option.telephone || null;
					this.budgetUpsert_address = option.address || null;
					this.budgetUpsert_identity = option.identityDocument || null;
					this.budgetUpsert_customer_evetual = option.eventual;
				} else {
					this.budgetUpsert_product = option.description || "";
					this.budgetUpsert_idProduct = option.idProduct || null;
					const total = option.sale3 || 0;
					this.budgetUpsert_quantity = numberFormatted(1);
					this.budgetUpsert_amount = numberFormatted(option.sale3 || 0);
					this.budgetUpsert_total = numberFormatted(total);
					this.invalidProduct = stringToNumber(this.budgetUpsert_total) <= 0 || this.budgetUpsert_idProduct === null;
					this.budgetUpsert_product_evetual = option.eventual;
				}
				this.dataDisable[key] = true;
				this.visibleSuggestion[key] = false;
			},

			async showBudget (item_) {
				let item = {};
				if (item_ && item_.idBudget) {
					[ item ] = this.budgets.filter(c => c.idBudget === item_.idBudget);
				}

				const {
					amount,
					comments,
					date,
					idBudget,
					idCustomer,
					identityDocument,
					cellPhone,
					telephone,
					address
				} = item;

				if (!idBudget) {
					this.titleModalBudget = "Novo Orçamento";
					this.editing = false;
				} else {
					this.titleModalBudget = "Edição de Orçamento";
					this.editing = true;

					this.budgetUpsert_idBudget = idBudget;
					this.budgetUpsert_comments = comments;
					this.budgetUpsert_date = stringToDateUTC(date);
					this.budgetUpsert_amount = amount;
					this.budgetUpsert_idCustomer = idCustomer || null;
					this.budgetUpsert_phone = cellPhone || telephone || null;
					this.budgetUpsert_address = address || null;
					this.budgetUpsert_identity = identityDocument || null;
				}
				this.invalidForm = true;
				this.$refs.modalBudget.show();
			},

			addProduct (idProduct) {
				const priceTotal = stringToNumberToFixed(this.budgetUpsert_total);
				this.grandTotal = stringToNumberToFixed(numberFormatted(this.grandTotal + priceTotal));

				const description = this.budgetUpsert_product;
				const quantity = this.budgetUpsert_quantity;
				const amount = this.budgetUpsert_amount;
				const total = this.budgetUpsert_total;
				const eventual = this.budgetUpsert_product_evetual;
				this.selectedProducts.push({
					idProduct,
					description,
					quantity,
					amount,
					total,
					eventual
				});
				this.budgetUpsert_idProduct = null;
				this.budgetUpsert_product = "";
				this.budgetUpsert_quantity = numberFormatted(1);
				this.budgetUpsert_amount = numberFormatted(0);
				this.budgetUpsert_total = numberFormatted(0);
				this.notProductsAdded = !this.selectedProducts.length;
				this.invalidProduct = true;
			},

			delProduct (index) {
				const priceTotal = stringToNumber(this.selectedProducts[index].total);
				this.grandTotal = stringToNumberToFixed(numberFormatted(this.grandTotal - priceTotal));

				this.selectedProducts.splice(index, 1);
				this.notProductsAdded = !this.selectedProducts.length;
				console.log(this.grandTotal);
			},

			async findBudgets (newFind) {
				if (newFind) {
					const exit = this.currentPage !== 1;
					this.pagination.page = 1;
					this.currentPage = 1;
					if (exit) return;
				}

				const limit = this.pagination.limit.value;
				this.pagination.offset = (this.pagination.page - 1) * limit;
				const offset = this.pagination.offset;
				const sortTable = this.sortTable;

				const filters = {
					limit,
					offset,
					initDate: dayjs(this.inicio).locale("pt-br").format("YYYY-MM-DD"),
					endDate: dayjs(this.fim).locale("pt-br").format("YYYY-MM-DD"),
					customer: this.customer,
					comments: this.comments,
					sortTable
				};

				this.loading = true;
				const result = await this.budgetService.getBudgetByParams(filters) || {};
				this.budgets = result.rows;
				this.linhas = result.rows.reduce((acc, c) => [
					...acc,
					{
						idBudget: c.idBudget,
						selected: false,
						cols: [
							c.numDoc.toString(),
							c.customerDetail?.description || c.nameCustomer,
							formatterSimple(c.date),
							numberFormatted(c.grandTotal),
							c.comments
						]
					}
				], []);

				this.pagination.count = result.count || 0;
				this.pagination.pages = this.pagination.count > limit ? Math.ceil(this.pagination.count / limit) : 1;
				this.loading = false;
				if (this.showFiltersForm)
					this.showFiltersForm = false;
			},

			showDetailsBudget (item) {
				const budgetSelected = this.budgets.find(ele => ele.idBudget === item.idBudget);
				this.filename = `orcamento_${(budgetSelected.nameCustomer.replace(/ /g, "_"))}${budgetSelected.idBudget}`;
				this.dataBudgetSelected = budgetSelected;
				this.dataBudgetSelected.dateFormated = dayjs(this.dataBudgetSelected.date).locale("pt-br").format("DD-MM-YYYY");

				this.detailsBudgetLines = budgetSelected.productDetails.reduce((acc, c) => [
					...acc,
					{
						cols: [
							c.description,
							numberFormatted(c.quantity),
							numberFormatted(c.amount),
							numberFormatted(c.total)
						]
					}
				], []);

				this.$refs.modalDetailsBudget.show();
			},

			async exportDataBudget (type) {
				try {
					const data = this.detailsBudgetLines;

					const exportType = {
						pdf: ["description", "quantity", "amount", "total"],
						xlsx: ["Produto", "Quantidade", "Preço", "Total"],
						csv: ["Produto", "Quantidade", "Preço", "Total"]
					};

					this.rowsReport = [];
					data.forEach((rec) => {
						this.rowsReport.push({
							[exportType[type][0]]: rec.cols[0],
							[exportType[type][1]]: rec.cols[1],
							[exportType[type][2]]: rec.cols[2],
							[exportType[type][3]]: rec.cols[3]
						});
					});

					if (type === "csv") {
						exportCSVFile(this.rowsReport, this.filename);
					} else if (type === "xlsx") {
						const worksheet = XLSX.utils.json_to_sheet(this.rowsReport);
						const workbook = XLSX.utils.book_new();
						XLSX.utils.book_append_sheet(workbook, worksheet, "pagina 1");
						XLSX.writeFile(workbook, `${this.filename}.xlsx`);
					} else {
						// type === "pdf"
						this.floatLayout = false;
						this.fields = [
							{ key: "description", label: "Produto" },
							{ key: "quantity", label: "Quantidade", tdClass: "text-right", thClass: "text-right" },
							{ key: "amount", label: "Preço", tdClass: "text-right", thClass: "text-right" },
							{ key: "total", label: "Total", tdClass: "text-right", thClass: "text-right" }
						];

						this.dataBudgetSelected.grandTotal = numberFormatted(this.dataBudgetSelected.grandTotal);
						this.headerData = {
							date: dayjs().locale("pt-br").format("DD-MM-YYYY"),
							hour: dayjs().locale("pt-br").format("HH:mm:ss"),
							user: this.userName,
							data: this.dataBudgetSelected
						};
						this.$refs.html2Pdf.generatePdf();
						this.floatLayout = true;
					}
				} catch (e) {
					console.log(e);
					this.$swal({
						title: "Erro",
						text: `Falha ao exportar dados! - "${e.message}"`,
						type: "error",
						showCancelButton: false,
						confirmButtonColor: "rgb(132, 170, 194)",
						confirmButtonText: "Ok"
					});
				} finally {

				}
			},

			formatter (date) {
				return dayjs(date).locale("pt-br").format("D [de] MMMM [de] YYYY");
			},

			validateAccount (select) {
				if (this.transferUpsert_idAccountExpense === this.budgetUpsert_idAccountIncome)
					if (select === "expense")
						this.transferUpsert_idAccountExpense = null;
					else
						this.budgetUpsert_idAccountIncome = null;
			},

			sortTableBy (v) {
				this.sortTable = v;
				this.findBudgets();
			},

			changePage () {
				this.pagination.page = this.currentPage;
				this.findBudgets();
			},

			changeItensByPage () {
				this.pagination.page = 1;
				this.findBudgets();
			},

			limparModal () {
				this.budgetUpsert_idBudget = null;
				this.budgetUpsert_comments = "";
				this.budgetUpsert_date = new Date();
				this.budgetUpsert_idCustomer = null;
				this.budgetUpsert_customer = "";
				this.budgetUpsert_identity = "";
				this.budgetUpsert_address = "";
				this.budgetUpsert_phone = "";

				this.budgetUpsert_idProduct = null;
				this.budgetUpsert_product = "";
				this.budgetUpsert_quantity = "1,00";
				this.budgetUpsert_amount = "0,00";
				this.budgetUpsert_total = "0,00";
				this.selectedProducts = [];
				this.notProductsAdded = true;
			},

			fecharModal (modal) {
				this.$refs[modal].hide();
				this.limparModal();
			},


			validateState (ref) {
				this.invalidForm = hasFieldsWithErrors(this.veeFields);
				if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated))
					return !this.veeErrors.has(ref);

				return null;
			},

			async save () {
				const payload = {
					idBudget: this.budgetUpsert_idBudget,
					comments: this.budgetUpsert_comments,
					date: this.budgetUpsert_date,
					idCustomer: this.budgetUpsert_idCustomer,
					addedProducts: this.selectedProducts,
					grandTotal: this.grandTotal,
					dataCustomer: {
						description: this.budgetUpsert_customer,
						identity: this.budgetUpsert_identity,
						address: this.budgetUpsert_address,
						telephone: this.budgetUpsert_phone,
						eventual: this.budgetUpsert_customer_evetual
					}
				};

				const config = {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				};

				this.$snotify.async("Aguarde...", "Salvando", async () => {
					try {
						if (payload.idBudget)
							await this.budgetService.updateBudget({payload});
						else
							await this.budgetService.createBudget({payload});

						this.fecharModal("modalBudget");
						this.findBudgets();
						this.limparModal();

						return {
							title: "Sucesso!",
							body: "Orçamento salvo com sucesso.",
							config
						};
					} catch (error) {
						const msgError = error.response.data.message || "Erro ao tentar salvar o orçamento!";
						throw {
							title: "Falha!",
							body: msgError,
							config
						};
					}
				});
			},

			async confirmaApagar (item_) {
				if (typeof (item_) === "string") {
					this.apagarVarios();
					return;
				}

				const [ item ] = this.transactions.filter((gu) => gu.idTransaction === item_.idTransaction);

				const deleteConfirmation = (await this.$swal({
					title: "Excluir",
					text: "Tem certeza que deseja deletar o orçamento?",
					type: "error",
					showCancelButton: true,
					cancelButtonColor: "#A6A8AB",
					confirmButtonColor: "#DD6B55",
					imageWidth: 50,
					imageHeight: 50,
					cancelButtonText: "Não",
					confirmButtonText: "Sim",
					reverseButtons: true
				})).value;

				if (deleteConfirmation)
					this.apagar(item.idTransaction);
			},

			async apagar (idTransaction) {
				const config = {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				};

				this.$snotify.async("Aguarde...", "Apagando", async () => {
					try {
						const payload = { idsTransaction: [idTransaction] };
						await this.transactionService.deleteTransaction({payload});
						this.findBudgets();

						return {
							title: "Sucesso!",
							body: "Transação deletada com sucesso.",
							config
						};
					} catch (error) {
						const msgError = error.response.data.message || "Erro ao tentar deletar a Transação!";
						throw {
							title: "Falha!",
							body: msgError,
							config
						};
					}
				});
			},

			filterDropDownActive () {
				this.showFiltersForm = !this.showFiltersForm;
			},

			AddDropDownActive () {
				this.showAddModal = !this.showAddModal;
			},

			async apagarVarios () {
				const idsTransaction = this.linhas.reduce((acc, item) => {
					if (item.selected)
						return [ ...acc, item.idTransaction ];

					return acc;
				}, []);

				if (!idsTransaction.length) return;

				const deleteConfirmation = (await this.$swal({
					title: "Excluir",
					text: "Tem certeza que deseja deletar a transação selecionada?",
					type: "error",
					showCancelButton: true,
					cancelButtonColor: "#A6A8AB",
					confirmButtonColor: "#DD6B55",
					imageWidth: 50,
					imageHeight: 50,
					cancelButtonText: "Não",
					confirmButtonText: "Sim",
					reverseButtons: true
				})).value;

				if (!deleteConfirmation)
					return;

				const config = {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				};

				this.$snotify.async("Aguarde...", "Apagando", async () => {
					try {
						const payload = { idsTransaction };
						await this.transactionService.deleteTransaction({payload});
						this.findBudgets();

						return {
							title: "Sucesso!",
							body: "transação deletada com sucesso.",
							config
						};
					} catch (error) {
						const msgError = error.response.data.message || "Erro ao tentar deletar a Transação!";
						throw {
							title: "Falha!",
							body: msgError,
							config
						};
					}
				});
			},

			async updateValue (key) {
				let total = 0;
				if (this.budgetUpsert_quantity && this.budgetUpsert_amount) {
					total = stringToNumber(this.budgetUpsert_quantity) * stringToNumber(this.budgetUpsert_amount);
				}
				this.budgetUpsert_total = numberFormatted(total);
				this.invalidProduct = total <= 0;

				const value = this[key];
				this[key] = await validateNumber(value);
			},

			showButonsTop () {
				this.itemSelected = this.linhas.some(linha => linha.selected);
			}
		}
	};
</script>

<style>
	.input-value {
		width: 100%;
		padding-right: 1rem;
	}

	.pdf-content {
		max-width: 100%; /* Asegura que el contenido no exceda el ancho */
		overflow: hidden; /* Evita que el contenido se desborde */
		word-wrap: break-word; /* Rompe palabras largas */
		transform: scale(0.7); /* Reduce el tamaño del contenido */
		transform-origin: top left;
	}
</style>
